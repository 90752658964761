import Vue from 'vue'
import VueRouter from 'vue-router'
import { checkLogin } from '@/plugins/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/index')
  },
  {
    path: '/index/create',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/create')
  },
  {
    path: '/contact/index',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/index')
  },
  {
    path: '/contact/screen',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/screen')
  },
  {
    path: '/contact/detail',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/detail')
  },
  {
    path: '/my',
    component: () => import(/* webpackChunkName: "my" */ '@/views/my/index')
  },
  {
    path: '/my/contact',
    component: () => import(/* webpackChunkName: "my" */ '@/views/my/contact')
  },
  {
    path: '/my/feedBack',
    component: () => import(/* webpackChunkName: "my" */ '@/views/my/feedBack')
  },
  {
    path: '/tool/index',
    component: () => import(/* webpackChunkName: "tool" */ '@/views/tool/index')
  },
  {
    path: '/contactStatistic/index',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contactStatistic/index')
  },
  {
    path: '/channelCode/index',
    component: () => import(/* webpackChunkName: "channelCode" */ '@/views/channelCode/index')
  },
  {
    path: '/channelCode/create',
    component: () => import(/* webpackChunkName: "channelCode" */ '@/views/channelCode/create')
  },
  {
    path: '/channelCode/detail',
    component: () => import(/* webpackChunkName: "channelCode" */ '@/views/channelCode/detail')
  },
  {
    path: '/contactWelcome/index',
    component: () => import(/* webpackChunkName: "contactWelcome" */ '@/views/contactWelcome/index')
  },
  {
    path: '/contactWelcome/create',
    component: () => import(/* webpackChunkName: "contactWelcome" */ '@/views/contactWelcome/create')
  },
  {
    path: '/workMoment/index',
    component: () => import(/* webpackChunkName: "workMoment" */ '@/views/workMoment/index')
  },
  {
    path: '/workMoment/create',
    component: () => import(/* webpackChunkName: "workMoment" */ '@/views/workMoment/create')
  },
  {
    path: '/medium/index',
    component: () => import(/* webpackChunkName: "medium" */ '@/views/medium/index')
  },
  {
    path: '/medium/detail',
    component: () => import(/* webpackChunkName: "medium" */ '@/views/medium/detail')
  },
  {
    path: '/medium/create',
    component: () => import(/* webpackChunkName: "medium" */ '@/views/medium/create')
  },
  {
    path: '/roomTemplatePull/index',
    component: () => import(/* webpackChunkName: "roomTemplatePull" */ '@/views/roomTemplatePull/index')
  },
  {
    path: '/roomTemplatePull/create',
    component: () => import(/* webpackChunkName: "roomTemplatePull" */ '@/views/roomTemplatePull/create')
  },
  {
    path: '/contactDeleteRemind/index',
    component: () => import(/* webpackChunkName: "contactDeleteRemind" */ '@/views/contactDeleteRemind/index')
  },
  {
    path: '/contactLost/index',
    component: () => import(/* webpackChunkName: "contactLost" */ '@/views/contactLost/index')
  },
  {
    path: '/sensitiveWordMonitor/index',
    component: () => import(/* webpackChunkName: "sensitiveWordMonitor" */ '@/views/sensitiveWordMonitor/index')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth')
  },
  {
    path: '/contactMessageBatchSend/create',
    component: () => import(/* webpackChunkName: "contactMessageBatchSend" */ '@/views/contactMessageBatchSend/create')
  },
  {
    path: '/roomMessageBatchSend/create',
    component: () => import(/* webpackChunkName: "roomMessageBatchSend" */ '@/views/roomMessageBatchSend/create')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  try {
    if (checkLogin(to, from, next) === false) {
      let agentId = to.query.agentId

      if (!agentId) {
        agentId = getCookie('workbench_agentId')
      }
      next({ path: '/login', query: { agentId: agentId, target: to.fullPath } })
    } else {
      next()
    }
  } catch (e) {
    next({ path: '/' })
  }
})

export default router
